interface PostalCodeRange {
  innerLimit: string;
  outerLimit: string;
  multiplier?: number;
  blocked?: boolean;
}

export interface ShippingFee {
  name: string;
  code: string;
  multiplier: number;
  postalCodes: PostalCodeRange[];
}

type ShippingCostProps = {
  price: number;
  shippingFees: ShippingFee[];
  country?: string;
  postalCode?: string;
};

export default function calculateShippingCost({
  price,
  shippingFees,
  country,
  postalCode,
}: ShippingCostProps): number {
  const storedCountry = country || localStorage.getItem('selectedCountry') || '';

  const storedPostalCode = postalCode || localStorage.getItem('postalCode') || '';

  const countryFee = shippingFees.find((fee) => fee.code === storedCountry);
  if (!countryFee) return 0;

  let multiplier = countryFee.multiplier - 1;

  if (countryFee.postalCodes.length > 0 && storedPostalCode) {
    const postalCodeRange = countryFee.postalCodes.find(
      (range) =>
        parseInt(storedPostalCode) >= parseInt(range.innerLimit) &&
        parseInt(storedPostalCode) <= parseInt(range.outerLimit),
    );

    if (postalCodeRange) {
      if (postalCodeRange.blocked) return 0;
      multiplier *= postalCodeRange.multiplier || 1;
    }
  }

  return price * multiplier;
}
